@import '~@styles/palette.scss';
@import '~@styles/breakpoints.scss';

@font-face {
  font-family: ProximaNova Light;
  src: url('./fonts/ProximaNova-Light.ttf');
  // src: url('./fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  // url('./fonts/ProximaNova-Light.woff') format('woff'),
  // url('./fonts/ProximaNova-Light.ttf') format('truetype');
  // font-weight: 300;
  // font-style: normal;
}

@font-face {
  font-family: ProximaNova Regular;
  src: url('./fonts/ProximaNova-Regular.ttf');
  // src: url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  // url('./fonts/ProximaNova-Regular.woff') format('woff'),
  // url('./fonts/ProximaNova-Regular.ttf') format('truetype');
  // font-weight: 300;
  // font-style: normal;
}

@font-face {
  font-family: ProximaNova Semibold;
  src: url('./fonts/ProximaNova-Semibold.ttf');
  // src: url('./fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  // url('./fonts/ProximaNova-Semibold.woff') format('woff'),
  // url('./fonts/ProximaNova-Semibold.ttf') format('truetype');
  // font-weight: 300;
  // font-style: normal;
}

@font-face {
  font-family: ProximaNova Bold;
  src: url('./fonts/ProximaNova-Bold.ttf');
  // src: url('./fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  // url('./fonts/ProximaNova-Bold.woff') format('woff'),
  // url('./fonts/ProximaNova-Bold.ttf') format('truetype');
  // font-weight: 300;
  // font-style: normal;
}


:global html {
  box-sizing: border-box;

}

:global body {
  color: $color-black-1000;
  margin: 0;
  padding: 0;

  font-smoothing: antialiased !important;
  -webkit-font-smoothing: antialiased !important;
}

:global * {
  box-shadow: none;
  box-sizing: inherit;

  &::after,
  &::before {
    box-sizing: inherit;
  }

  &:focus {
    outline: none;
  }
}

:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6 {
  margin: 0;
}

:global button {
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: all .2s;
}

:global h1 {
  font-family: ProximaNova Semibold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  font-style: normal;

  @include breakpoint(tablet) {
    font-size: 32px;
    line-height: 40px;
  }
  @include breakpoint(mobile) {
    font-size: 24px;
    line-height: 32px;
  }
}

:global h2 {
  font-family: ProximaNova Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  @include breakpoint(tablet) {
    font-size: 24px;
    line-height: 32px;
  }
  @include breakpoint(mobile) {
    font-size: 24px;
    line-height: 32px;
  }
}

:global p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.32px;
  margin: 0;
  @include breakpoint(tablet) {
    font-size: 18px;
    line-height: 26px;
  }
  @include breakpoint(mobile) {
    font-size: 16px;
    line-height: 21px;
  }
}

a:any-link {
  text-decoration-line: none;
}

:global span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

:global .swiper-slide {
  width: 100% !important;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

iframe {
  display: none;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

option {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

:global .react-datepicker__header__dropdown {
  margin: 12px 0 0 0;
}

:global .react-datepicker__month-select,
:global .react-datepicker__year-select {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;

  & > option {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
