@import '~@styles/reset.scss';
@import '~@styles/palette.scss';

body {
  font-family: "ProximaNova Regular", sans-serif;
  height: fit-content;
  min-height: 100%;
}

* {
  scroll-behavior: smooth;
  scroll-margin: 0;
  scroll-padding: 0;
}

.Root {
  background: $color-white-1000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
