@import '~@styles/palette.scss';
@import '~@styles/breakpoints.scss';
@import '~@styles/palette.scss';

.ActivityPaidSuccess {
  &_root {
    width: 100%;
    background: $color-white-1000;
    border-radius: 16px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 4px 48px rgba(32, 61, 179, 0.12);

    @include breakpoint(desktop) {
      max-width: 808px;
      padding: 0 104px 64px;
    }
    @include breakpoint(tablet) {
      max-width: calc(100% - 64px);
      padding: 42px 103px 56px;
      margin: 0 auto 80px;
    }

    @include breakpoint(mobile) {
      max-width: calc(100% - 32px);
      padding: 40px 16px 110px;
      margin: 0 auto 64px;
    }
  }

  &_qr {
    height: 339px;
  }

  &_mobileMock {
    display: none;
    @include breakpoint(tablet) {
      display: block;
      height: 240px;
    }
    @include breakpoint(mobile) {
      display: block;
      height: 207px;
    }
  }

  &_title {
    color: $color-black-1000;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "ProximaNova Regular", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin: 0 0 16px 0;
    @include breakpoint(tablet) {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin: 32px 0 8px 0;
    }
    @include breakpoint(mobile) {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      margin: 32px 0 12px 0;
    }
  }

  &_text {
    color: $color-gray-1000;
    text-align: center;
    font-family: "ProximaNova Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    @include breakpoint(tablet) {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.32px;
    }
    @include breakpoint(mobile) {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.32px;
    }
  }

  &_icons {
    @include breakpoint(tablet) {
      width: 371px;
      margin: 24px 0 0 0;
    }
    @include breakpoint(mobile) {
      width: 166px;
      flex-direction: column;
      margin: 24px 0 0 0;
    }
  }

  &_icon {}
}
