$color-white-1000: #FFFFFF;

$color-black-1000: #03040A;
$color-indigo-black-1000: #2A3768;

$color-gray-1000: #636675;
$color-gray-500: #CED1DB;
$color-mid-gray-2: #8E919E;
$color-mid-gray: #C5C7D1;
$color-light-gray: #DCDEE6;
$color-light-gray-2: #CED1DB;

$color-blue-1000: #1742F0;
$color-blue-900: #072EC8;
$color-blue-800: #1D43EB;
$color-blue-500: #255DFF;
$color-blue-200: #ecf0fe;
$color-blue-100: #f1f4fe;

$color-orange-1000: #EA9516;

$color-red-1000: #E03232;
